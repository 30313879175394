import styled from 'styled-components';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';

const Section = styled.section `
  background-color: #19202c;
    padding-bottom: 50px;
  padding-top: 50px;
  @media (max-width: 1024px) {
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @media (max-width: 480px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const SectionHeading = styled.div `
  text-align: center;
  p {
    color: ${ themeGet( 'colors.textColorLight' ) };
  }
`;

export const Grid = styled.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.div `
  color: ${ themeGet( 'colors.white' ) };
  border-radius: 6px;
  padding: 20px 60px;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
    display: flex;
    flex-direction: column;
    align-items: center;
  @media (max-width: 1024px) {
    padding: 28px;
  }
  @media (max-width: 768px) {
    padding: 40px 30px 30px;
  }
  @media (max-width: 480px) {
    padding: 30px;
  }
  figure {
padding : 1.5rem;
background-color : #5E77A6;
width : fit-content;
border-radius : 20px;
    img {
      margin: 0 auto;
      @media (min-width: 769px) and (max-width: 1024px) {
        max-width: 75px;
      }
    }
  }
  p {
    color: ${themeGet( 'colors.textColorLight' )};
    line-height: 1.75;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default Section;