import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';
import styled from 'styled-components';

const Section = styled.section `
  padding-top: 75px;
  padding-bottom: 75px;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const SectionHeading = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
text-align : center;
  h4 {
    font-family: Noto Serif KR;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin: 0;
    color: ${ themeGet( 'colors.textColorAlt' ) };
    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 24px;
      line-height: 34px;
    }
    span {
      color: ${ themeGet( 'colors.linkColor' ) };
    }
    @media (max-width: 480px) {
      text-align: center;
    }
  }
`;

export const Figure = styled.figure `
  margin: 0;
  text-align: center;
  opacity: 0.7;
`;


export const SliderWrapper = styled.div `
  margin-top: 55px;
  display: flex;
  gap: 8rem;
  align-items: center;
  width: 100%;
    justify-content: center;

  /* Medium screens (tablets) */
  @media only screen and (max-width: 1324px) {
    gap: 5rem;
    margin-top: 50px;
  flex-wrap: wrap; /* Add flex-wrap to ensure images wrap on smaller screens */
  }

  /* Small screens (mobile) */
  @media only screen and (max-width: 767px) {
    margin-top: 45px;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }

  /* Very small screens (extra small phones) */
  @media only screen and (max-width: 480px) {
    margin-top: 30px;
    gap: 2rem;
    align-items: flex-start; /* Better positioning for tiny screens */
    padding: 0 1rem; /* Add some padding to prevent overflow */
  }

  figure {
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05); /* Add hover effect to improve UX */
    }

    img {
      opacity: 0.7;
      max-width: 100%; /* Ensure the images do not overflow their container */
    }
  }
`;

export default Section;