import React from 'react';
import styled from 'styled-components';
import StrideAheadLogo from '../../common/assets/StrideaheadLogo.png';

const Footer = ( { clientConfig } ) => {
    return (
        <FooterContainer>
            <PoweredBy>
                Powered by <Logo src={ StrideAheadLogo } alt="Stride Ahead" />
            </PoweredBy>
            <FooterLinks>
                <Copyright>© Copyright © 2024 Jaro Education. All rights reserved.</Copyright>
                <Link href="https://www.jaroeducation.com/terms-of-use/">Privacy policy</Link>
                <Link href="https://www.jaroeducation.com/terms-of-use/">Terms & Conditions</Link>
            </FooterLinks>
        </FooterContainer>
    );
};

// Styled Components

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
gap : 2rem;
  align-items: center;
  padding: 20px 20px 8rem 20px;
  font-family: Arial, sans-serif;
  color: #6c6c6c;
  font-size: 14px;
  text-align: center;

  @media (min-width: 508px) {
  flex-direction: column;
    justify-content: space-between;
    text-align: left;
  padding: 20px 20px 7rem 20px;
  }
`;

const Copyright = styled.div`
  margin-bottom: 10px;

  @media (min-width: 508px) {
    margin-bottom: 0;
  }
`;

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;

  @media (min-width: 508px) {
    margin-bottom: 0;
  }
`;

const Logo = styled.img`
  height: 20px;
  margin-left: 5px;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @media (min-width: 508px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const Address = styled.div`
  font-style: italic;
`;

const Link = styled.a`
  text-decoration: none;
  color: #6c6c6c;

  &:hover {
    color: #333;
  }
`;

export default Footer;
