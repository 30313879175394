import Heading from '../../common/components/Heading';
import { Image } from 'antd';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { whyChooseUs } from '../../data/index';
import React from 'react';
import Section, { Grid, Item, SectionHeading } from './whyYouChoose.style';

const WhyYouChoose = ( props ) => {
    return (
        <Section id="about" { ...props }>
            <Container width="1400px">
                <SectionHeading>
                    <h2>
                        How Does It Work?
                    </h2>
                </SectionHeading>
                <Grid>
                    { whyChooseUs?.map( ( howTo ) => (
                        <Item key={ howTo?.id }>
                            <figure>
                                <Image src={ howTo?.icon } alt="icon" preview={ false } />
                            </figure>
                            <h2>
                                { howTo?.title }
                            </h2>
                            <Text content={ howTo?.text } />
                        </Item>
                    ) ) }
                </Grid>
            </Container>
        </Section>
    );
};

export default WhyYouChoose;
