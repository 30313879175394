import feature1 from '../common/assets/feature1.png';
import feature2 from '../common/assets/feature2.png';
import feature3 from '../common/assets/feature3.png';
import feature4 from '../common/assets/feature4.png';
import feature5 from '../common/assets/feature5.png';
import feature6 from '../common/assets/feature6.png';

import globalCarerLogo from '../common/assets/gsLogo.png'

import SkillLogo from '../common/assets/companyLogos/skillLogo.png';
import IndiaEducationLogo from '../common/assets/companyLogos/indiaEducation.png';
import ApnNewsLogo from '../common/assets/companyLogos/apnnewslogo.png';
import EconomicTimesLogo from '../common/assets/companyLogos/economicTimes.png';

import icon1 from '../common/assets/whychooseUs/icon1.png';
import icon2 from '../common/assets/whychooseUs/icon2.png';
import icon3 from '../common/assets/whychooseUs/icon3.png';

import icon5 from '../common/assets/FeaturesSection/icon5.png';
import icon6 from '../common/assets/FeaturesSection/icon6.png';
import icon7 from '../common/assets/FeaturesSection/icon7.png';
import icon8 from '../common/assets/FeaturesSection/icon8.png';
import icon9 from '../common/assets/FeaturesSection/icon9.png';
import icon10 from '../common/assets/FeaturesSection/icon10.png'

import user1 from '../common/assets/Testimonials/user1.png';
import user2 from '../common/assets/Testimonials/user2.png';
import user3 from '../common/assets/Testimonials/user3.png';

import person1 from '../common/assets/Testimonials/image1.jpg';
import person2 from '../common/assets/Testimonials/image2.jpg';
import person3 from '../common/assets/Testimonials/image3.png';
import person4 from '../common/assets/Testimonials/image4.png';

export const USERTESTIMONIALS = [{
    id: 1,
    photo: person1,
    rating: 4,
    name: 'Mehak Hundal',
    text: `I transitioned from the postal dept to corporate banking with IIM Nagpur's Fintech course. Jaro's expert consultancy helped me secure a 40% salary hike`,
},
{
    id: 2,
    photo: person2,
    rating: 3,
    name: 'Satyajit Sutradhar',
    text: `Jaro Education's Digital Marketing expertise at IIM Nagpur helped me secure a promotion and hike with excellent profile-building and job recommendation services. Highly recommended.`,
},
{
    id: 3,
    photo: person3,
    rating: 5,
    name: 'Snover Sanduja',
    text: `In 2022, I switched from pursuing a PhD at IIT Delhi to a corporate job. An ad for Jaro Education's Brand Management course from IIT Delhi caught my eye, and I enrolled. Six months later, I have valuable memories and learnings. Grateful for this enriching experience.`,
},
{
    id: 4,
    photo: person4,
    rating: 5,
    name: 'Riya Gulati',
    text: `Jaro Education’s faculty was incredibly patient and understanding. They recommended the right courses and addressed all our doubts. The support team also ensured a smooth process. Now, I'm happily completing my Online MBA.`,
},
]

export const whyChooseUs = [
    {
        id: 1,
        icon: icon1,
        title: 'Quick Self - Assessment',
        text: `Answer a few quick questions to tailor the ROI calculation to your career goals.`,
        linkLabel: 'Start Earning',
        link: '#',
    },
    {
        id: 2,
        icon: icon2,
        title: 'Calculation & Analysis',
        text: `The calculator uses AI to analyze your answers and see the potential return on your upskilling investment within seconds.`,
        linkLabel: 'Sign up your store',
        link: '#',
    },
    {
        id: 3,
        icon: icon3,
        title: 'Instant Insights',
        text: `See your ROI report instantly with details on potential returns and book a free 1:1 counselling with our career experts.`,
        linkLabel: 'Get the app',
        link: '#',
    },
];

export const FEATURES_SECTION = [{
    icon: feature1,
    title: 'App Development',
    description:
        (
            <>
                Discover careers that match your personality, interests, and aptitudes.
            </>
        ),
},
{
    icon: feature2,
    title: '10 Times Award',
    description: (
        <>
            Built by expert psychometricians.
        </>
    ),
},
{
    icon: feature3,
    title: 'Cloud Storage',
    description: (
        <>
            Enhanced assessments to reduce bias and improve clarity.
        </>
    )
},
{
    icon: feature4,
    title: 'Customization',
    description: (
        <>
            Tests cover critical thinking, verbal, numerical abilities, and more.
        </>
    ),
},
{
    icon: feature5,
    title: 'UX Planning',
    description: (
        <>
            Smooth transition to profile building and study abroad support.
        </>
    ),
},
{
    icon: feature6,
    title: 'Customer Support',
    description: (
        <>
            Get a personalized career roadmap tailored for your success.
        </>
    ),
},
];


export const faq = [
    {
        id: 1,
        expend: true,
        title: 'What is the purpose of this career assessment?',
        description: 'This career assessment helps you identify careers that align with your interests and work preferences. It provides valuable insights for planning your academic and career journey.',
    },
    {
        id: 2,
        title: 'How does the assessment work?',
        description: 'You will answer 36 questions that assess your interests. Based on your responses, the assessment generates a report identifying careers that best match your interests and personal strengths.',
    },
    {
        id: 3,
        title: 'What makes this assessment different from other career assessments?',
        description: 'Our assessment is concise, clear, and practical. Designed for easy understanding, it provides unbiased, reliable career suggestions tailored to a wide range of career fields, requiring no special preparation.',
    },
    {
        id: 4,
        title: 'How trustworthy is this assessment?',
        description: 'Developed by career experts using robust theoretical foundations, this assessment has been carefully crafted to provide accurate and relevant insights.',
    },
    {
        id: 5,
        title: 'What should I do after receiving my assessment report?',
        description: 'After reviewing your assessment report, we recommend scheduling a counseling session to discuss your career options in-depth. A career expert will guide you through potential paths that align with your interests and strengths.',
    },
    {
        id: 6,
        title: 'How do I get started with the assessment?',
        description: 'Getting started is easy! Sign up through our website, and you’ll receive instructions on completing the assessment and accessing your personalized career report.',
    }
];


export const contactInfo = {
    contactInfo: {
        title: 'Need help? We are here for you',
        phone: `+91-6012556845`,
        email: `QStudy36@gmail.com`,
    },
};

export const FOOTER_DATA = {
    strideLogo: globalCarerLogo,
    logo: globalCarerLogo,
    menu: [
        {
            link: '#',
            label: 'Support',
        },
        {
            link: '#',
            label: 'About Us',
        },
        {
            link: '#',
            label: 'Privacy',
        },
        {
            link: '#',
            label: 'Contact',
        },
    ],
    social: [
        {
            icon: globalCarerLogo,
            link: '#',
        },
    ],
};

export const clients = [
    {
        id: 1,
        icon: ApnNewsLogo,
        link: 'https://www.apnnews.com/jaro-education-ai-powered-skill-gap-calculator-is-revolutionizing-personalized-career-counseling-and-organizational-assessment/',
    },
    {
        id: 2,
        icon: SkillLogo,
        link: 'https://skilloutlook.com/education/jaro-education-introduced-first-ever-ai-powered-roi-calculator-for-upskilling#google_vignette',
    },
    {
        id: 3,
        icon: IndiaEducationLogo,
        link: 'https://indiaeducationdiary.in/jaro-education-introduced-first-ever-ai-powered-roi-calculator-for-upskilling/',
    },
    {
        id: 4,
        icon: EconomicTimesLogo,
        link: 'https://economictimes.indiatimes.com/markets/ipos/fpos/jaro-education-files-draft-papers-with-sebi-to-mobilise-rs-570-crore-via-ipo/articleshow/114416163.cms?from=mdr',
    }];

export const features = [
    {
        id: 1,
        icon: icon5,
        title: 'Quantify Your Investment',
        description: `Get a clear, data-driven view of the financial returns on upskilling, helping you make informed education choices.`,
    },
    {
        id: 2,
        icon: icon6,
        title: 'Career Growth Insights',
        description: `Discover how the right program can enhance your earning potential and career trajectory, tailored to your industry.`,
    },
    {
        id: 3,
        icon: icon7,
        title: 'Time-Saving Evaluation',
        description: `Quickly assess the ROI of multiple programs without lengthy research, so you can decide faster.`,
    },
    {
        id: 4,
        icon: icon8,
        title: 'Personalized Projections',
        description: `Tailor the calculator to your unique career and educational goals, making predictions highly relevant and precise.`,
    },
    {
        id: 5,
        icon: icon9,
        title: 'Set Realistic Goals',
        description: `See achievable outcomes for your investment, motivating you to take the next step confidently.`,
    },
    {
        id: 6,
        icon: icon10,
        title: 'Improved Decision Confidence',
        description: `Validate your educational investment decisions with data-backed projections, leaving less room for second-guessing.`,
    },
];

export const testimonials = [
    {
        id: 1,
        avatar: user1,
        name: 'Mariana Dickey',
        designation: 'UI Designer',
        quote: `OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.`,
    },
    {
        id: 2,
        avatar: user2,
        name: 'Jonathan Taylor',
        designation: 'CEO at Creativex',
        quote: `We’re driven beyond just finishing the projects. We want to find solutions with the assessment. OMG! I cannot believe that I have got a brand new landing page.`,
    },
    {
        id: 3,
        avatar: user3,
        name: 'Krish Kendall',
        designation: 'Creative Director',
        quote: `Pick one of our stock themes, or create your custom theme with the most advanced theme editor on any online survey building tool.`,
    },
];