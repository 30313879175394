import JaroEduLandingPage from './RoutesContainer/RouteContainer';
import './App.css';

function App() {
    return (
        <>
            <JaroEduLandingPage />
        </>
    );
}

export default App;
