import { useState } from "react";
import axios from "axios";
import { BASE_URL, FETCH_CLIENT_CONFIG } from '../data/constant';

const useFetchClientConfig = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const fetchClientConfig = async ( clientName ) => {
        setIsLoading( true );

        try {
            const res = await axios.get( `${ BASE_URL }/${ FETCH_CLIENT_CONFIG }/${ clientName }/` );
            setResponse( res );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, fetchClientConfig };
};

export default useFetchClientConfig;