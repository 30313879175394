import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from '../../common/assets/SampleReportImageJaro.png';

const PartnerSection = ( {
    row,
    col,
    title,
    description,
    button,
    textArea,
    imageArea,
    clientConfig
} ) => {
    return (
        <PartnerSectionWrapper>
            <Container width="1400px">
                <Box { ...row }>
                    <Box { ...col } { ...textArea }>
                        <Heading
                            { ...title }
                            content="See Your Upskilling ROI in Action"
                        />
                        <Text
                            { ...description }
                            content="Know your potential! Turn ambitions into concrete plans. See the financial impact of upskilling in real terms – because your career deserves clarity!"
                        />
                    </Box>
                    <Box { ...col } { ...imageArea }>
                        <Image src={ Partner } alt="Domain Image" preview={ false } />
                    </Box>
                    <Box { ...col } >
                        <a href='https://roi-results.jaroeducation.com/?user_id=20490&report_id=12654'>
                            <Text
                                { ...button }
                                style={ { backgroundColor: '#1A3783', borderRadius: 10 } }
                                content="Download Your Personalized Report"
                            />
                        </a>
                    </Box>
                </Box>
            </Container>
        </PartnerSectionWrapper>
    );
};

PartnerSection.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    button: PropTypes.object,
    textArea: PropTypes.object,
    imageArea: PropTypes.object,
    clientConfig: PropTypes.object,
};

PartnerSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        flexDirection: 'column',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
    },
    imageAreaRow: {
        flexDirection: 'row-reverse',
    },
    col: {
        pr: '15px',
        pl: '15px',
        pt: '25px',
        flexBox: true,
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    textArea: {
        width: ['100%', '100%', '55%', '50%', '60%'],
    },
    imageArea: {
        width: ['100%', '100%', '45%', '50%', '58%'],
        mt: ['40px', '40px', '0', '0', '0'],
    },
    title: {
        fontSize: ['32px', '24px', '36px', '36px'],
        fontWeight: '700',
        color: '#000000',
        letterSpacing: '-0.025em',
        textAlign: 'center',
        mb: '15px',
        lineHeight: '1.25',
    },
    description: {
        fontSize: '18px',
        color: '#504E4E',
        lineHeight: '1.75',
        textAlign: 'center',
        mb: '33px',
    },
    button: {
        fontSize: '18px',
        fontWeight: '600',
        py: '1rem',
        px: '2rem',
        color: '#FFFFFF',
        mb: '0px'
    },
};

export default PartnerSection;