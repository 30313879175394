import React from 'react';
import Container from '../../common/components/UI/Container';
import { Image } from 'antd';
import Heading from '../../common/components/Heading';
import Section, { SectionHeading, Grid, Item } from './features.style';
import styled from 'styled-components';

import { features } from '../../data';

const FeaturesSection = ( { clientConfig } ) => {
    return (
        <Section id="features" style={ { backgroundColor: '#0B2472' } }>
            <Container width="1400px">
                <SectionHeading>
                    <h2 style={ { color: '#ffffff' } }>
                        Why Use The ROI Calculator?
                    </h2>
                </SectionHeading>
                <Grid>
                    { features.map( ( feature ) => (
                        <Item key={ feature.id }>
                            <figure>
                                <Image preview={ false } src={ feature.icon } alt="icon" />
                            </figure>
                            <h2 style={ { color: '#ffffff' } }>
                                { feature.title }
                            </h2>
                            <Text>{ feature.description }</Text>
                        </Item>
                    ) ) }
                </Grid>
            </Container>
        </Section>
    );
};


const SubHeading = styled.div`
  font-weight: 700;
font-size : 30px;
color : #FFFFFF;
    margin-bottom: 10px;

  @media (min-width: 508px) {
    margin-bottom: 10px;
  }
`;

const Text = styled.div`
  font-weight: 400;
font-size : 18px;
  color: rgba(255, 255, 255, 0.7); /* 70% white */

  @media (min-width: 508px) {
    margin-bottom: 0;
  }
`;


export default FeaturesSection;
