import React, { Fragment, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { saasClassicTheme } from '../common/theme/index';
import ResetCSS from '../common/style';
import { GlobalStyle, ContentWrapper } from '../components/qstudy.style';
import BannerSection from '../components/Banner/index';
import PartnerSection from '../components/Partner';
import StickyButton from '../components/StickyButton';
import useFetchClientConfig from '../api/FetchClientConfig';
import Clients from '../components/Clients';
import FeaturesSection from '../components/Features';
import UserTestimonialSection from '../components/Testimonial';
import WhyYouChoose from '../components/WhyYouChoose';
import Footer from '../components/Footer';

const JaroEduLandingPage = () => {

    const { response, error, isLoading, fetchClientConfig } = useFetchClientConfig();
    const clientName = 'jaroedu';

    useEffect( () => {
        fetchClientConfig( clientName );
    }, [] )

    console.log( 'response', response );

    return (
        <ThemeProvider theme={ saasClassicTheme }>
            <Fragment>
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <BannerSection clientConfig={ response?.data } />
                    <Clients />
                    <WhyYouChoose />
                    <FeaturesSection clientConfig={ response?.data } />
                    <PartnerSection clientConfig={ response?.data } />
                    <UserTestimonialSection />
                    <Footer clientConfig={ response?.data } />
                    <StickyButton clientConfig={ response?.data } />
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};

export default JaroEduLandingPage;
