import React, { Fragment } from 'react';
import Slider from 'react-slick';
import Container from '../../common/components/UI/Container';
import { Image } from 'antd';
import Section, {
    SectionHeading,
    SliderWrapper,
    Figure,
} from './clients.style';
import { clients } from '../../data';

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
        {
            breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
            settings: 'unslick',
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};

const Clients = () => {
    return (
        <Section>
            <Container width="1400px">
                <SectionHeading>
                    <h2>
                        What Does The Media Say About Us? Find Out!
                    </h2>
                </SectionHeading>
                <SliderWrapper>
                    { clients.map( ( client, i ) => (
                        <Figure key={ client.id }>
                            <a href={ client.link }>
                                <Image src={ client.icon } alt="logo" preview={ false } width={ 250 } />
                            </a>
                        </Figure>
                    ) ) }
                </SliderWrapper>
            </Container>
        </Section>
    );
};

export default Clients;
