import React from 'react';
import Container from '../../common/components/UI/Container/index';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Lottie from 'lottie-react';
import Text from '../../common/components/Text';
import {
    Section,
    ContentWrapper,
    TextContent,
    Illustration,
    ButtonGroup,
    AnimationContent,
} from './banner.style';
import AnimationData from '../../common/assets/animation/Animation - 1731574657589.json';
import JaroEduLogo from '../../common/assets/jaro logo_white.png';
import { Image } from 'antd';

const BannerSection = ( { clientConfig } ) => {

    const handleProceedToTest = () => {
        const currentUrlParams = new URLSearchParams( window.location.search );
        const baseUrl = 'https://roi.jaroeducation.com/jaroedu/survey/TXAY5rw898S';
        const newUrl = `${ baseUrl }?${ currentUrlParams.toString() }`;
        window.open( newUrl, '_blank' );
    }

    return (
        <Section id="home" style={ { backgroundColor: clientConfig?.primary_color } }>
            <Container>
                <ContentWrapper>
                    <TextContent>
                        <Image src={ JaroEduLogo } preview={ false } width={ 300 } />
                        <Heading content="Is Upskilling Worth The Investment?" />
                        <Text content="Calculate the Return on Your Upskilling Investment with India’s First AI Powered Upskilling ROI Calculator!" />
                        <ButtonGroup>
                            <Button title="Calculate Now!" onClick={ handleProceedToTest } />
                        </ButtonGroup>
                    </TextContent>
                    <AnimationContent>
                        <Lottie animationData={ AnimationData } loop={ true } style={ { height: '600px' } } />
                    </AnimationContent>
                </ContentWrapper>
            </Container>
        </Section>
    );
};

export default BannerSection;

