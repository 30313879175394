import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';
import GlideCarousel from '../../common/components/GlideCarousel';
import GlideSlide from '../../common/components/GlideCarousel/glideSlide';

import { USERTESTIMONIALS } from '../../data/index';

import {
    TestimonialSlideWrapper,
    TestimonialItem,
    TestimonialMeta,
    AuthorInfo,
    AuthorImage,
} from './testimonial.style';

const UserTestimonialSection = ( {
    sectionWrapper,
    secTitleWrapper,
    secText,
    secHeading,
    reviewTitle,
    review,
    name,
    designation,
} ) => {
    const carouselOptions = {
        type: 'carousel',
        autoplay: 3000,
        perView: 2,
        gap: 30,
        animationDuration: 800,
        breakpoints: {
            990: {
                perView: 1,
            },
        },
    };

    return (
        <Box { ...sectionWrapper } as="section" id="testimonial_section">
            <Container width="1400px">
                <Box { ...secTitleWrapper }>
                    {/*<Text { ...secText } content="Testimonials" />*/ }
                    <Heading
                        { ...secHeading }
                        content="What Do People Think About Us?"
                    />
                    <Text { ...secText } content="Explore the inspiring journeys of individuals who have transformed their careers with our programs." />
                </Box>
                <TestimonialSlideWrapper>
                    <GlideCarousel
                        options={ carouselOptions }
                        carouselSelector="testimonial__slider"
                        controls={ false }
                        bullets={ true }
                        numberOfBullets={ USERTESTIMONIALS.length }
                    >
                        <>
                            { USERTESTIMONIALS.map( ( item, index ) => (
                                <GlideSlide key={ `testimonial-slide-${ index }` }>
                                    <TestimonialItem>
                                        {/*<Heading as="h3" content={ item.title } { ...reviewTitle } />*/ }
                                        <Text content={ item.text } { ...review } />
                                        <TestimonialMeta>
                                            <AuthorInfo>
                                                <AuthorImage>
                                                    <Image
                                                        src={ item.photo }
                                                        alt={ `reviewer-image-${ index }` }
                                                        preview={ false }
                                                    />
                                                </AuthorImage>
                                                <Box>
                                                    <Heading as="h3" content={ item.name } { ...name } />
                                                </Box>
                                            </AuthorInfo>
                                        </TestimonialMeta>
                                    </TestimonialItem>
                                </GlideSlide>
                            ) ) }
                        </>
                    </GlideCarousel>
                </TestimonialSlideWrapper>
            </Container>
        </Box>
    );
};

UserTestimonialSection.propTypes = {
    sectionHeader: PropTypes.object,
};

UserTestimonialSection.defaultProps = {
    sectionWrapper: {
        pt: ['60px', '80px', '90px', '100px', '50px'],
        pb: ['60px', '80px', '90px', '100px', '70px'],
    },
    secTitleWrapper: {
        mb: ['40px', '60px'],
    },
    secText: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: ['18px', '18px', '18px', '18px'],
        fontWeight: '500',
        color: '#182C59',
        mb: '12px',
        mt: '12px',
        pr: '10px',
        pl: '10px'
    },
    secHeading: {
        textAlign: 'center',
        fontSize: ['32px', '24px', '36px', '36px'],
        fontWeight: '700',
        color: '#0F2137',
        letterSpacing: '-0.025em',
        mb: '0',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '700px',
        maxWidth: '100%',
    },
    reviewTitle: {
        fontSize: ['15px', '16px'],
        fontWeight: '500',
        color: '#343d48',
        lineHeight: '1.5',
        mb: '13px',
    },
    review: {
        fontSize: ['16px', '19px'],
        fontWeight: '300',
        color: '#343d48',
        lineHeight: '1.7',
        mb: 0,
    },
    name: {
        fontSize: ['14px', '16px'],
        fontWeight: '500',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '8px',
    },
    designation: {
        fontSize: ['12px', '14px'],
        color: '#6f7a87',
        mb: 0,
    },
};

export default UserTestimonialSection;